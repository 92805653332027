import React, { Component } from "react"
import { connect } from "react-redux"
import {
    TabContent, TabPane, Container, Row, Col, Spinner,
    Nav, NavItem, NavLink, Card, CardBody, CardTitle, CardText, CardImg
} from "reactstrap"
import ReactPaginate from 'react-paginate'
import { injectIntl, FormattedMessage, Link } from "gatsby-plugin-intl"

import Layout from "../containers/Layout"
import SEO from "../components/seo"
import axios from "../api/axios"
import NoImg from "../images/no_img.png"


class SearchPage extends Component {

    state = {
        allResults: [],
        achievementsResults: [],
        linksResults: [],
        agreementsResults: [],
        mapsResults: [],
        activeTab: 'all',
        isLoading: true,
        totalResultsCount: 0,
        resultsOffset: 1,
        resultsType: ""
    }



    componentDidMount() {
        const query = new URL(this.props.location.href).searchParams.get("query");
        if (this.props.selectedRegion !== null) {
            const regionParam = this.props.selectedRegion.code !== 'GCC' ? `&region=${this.props.selectedRegion.id}` : ""
            axios(this.props.intl.locale, `search/?query=${query}&offset=${this.state.resultsOffset}&type=${this.state.resultsType}` + regionParam)
                .then(response => {
                    if (response.data.total_counts === 0) {
                        this.setState({
                            isLoading: false
                        })
                    }
                    else {
                        let allResults = []
                        let achievementsResults = []
                        let agreementsResults = []
                        let linksResults = []
                        let mapsResults = []
                        response.data.results.map(result => {
                            allResults.push(result)
                            switch (result.index_type) {
                                case 'achievements':
                                    achievementsResults.push(result)
                                    break

                                case 'agreements':
                                    agreementsResults.push(result)
                                    break

                                case 'links':
                                    linksResults.push(result)
                                    break

                                case 'static_maps':
                                    mapsResults.push(result)
                                    break

                                default:
                                    return
                            }
                        })
                        this.setState({
                            isLoading: false,
                            allResults: allResults,
                            achievementsResults: achievementsResults,
                            agreementsResults: agreementsResults,
                            mapsResults: mapsResults,
                            linksResults: linksResults,
                            totalResultsCount: response.data.total_counts
                        })
                    }
                })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.selectedRegion !== prevProps.selectedRegion
            || this.props.location !== prevProps.location
            || this.state.resultsOffset !== prevState.resultsOffset
            || this.state.resultsType !== prevState.resultsType) {
            const query = new URL(this.props.location.href).searchParams.get("query");
            const regionParam = this.props.selectedRegion.code !== 'GCC' ? `&region=${this.props.selectedRegion.id}` : ""
            axios(this.props.intl.locale, `search/?query=${query}&offset=${this.state.resultsOffset}&type=${this.state.resultsType}` + regionParam)
                .then(response => {
                    if (response.data.total_counts === 0) {
                        this.setState({
                            isLoading: false
                        })
                    }
                    else {
                        let allResults = []
                        let achievementsResults = []
                        let agreementsResults = []
                        let linksResults = []
                        let mapsResults = []
                        response.data.results.forEach(result => {
                            allResults.push(result)
                            switch (result.index_type) {
                                case 'achievements':
                                    achievementsResults.push(result)
                                    break

                                case 'agreements':
                                    agreementsResults.push(result)
                                    break

                                case 'links':
                                    linksResults.push(result)
                                    break

                                case 'static_maps':
                                    mapsResults.push(result)
                                    break

                                default:
                                    return
                            }
                        })
                        this.setState({
                            isLoading: false,
                            allResults: allResults,
                            achievementsResults: achievementsResults,
                            agreementsResults: agreementsResults,
                            mapsResults: mapsResults,
                            linksResults: linksResults,
                            totalResultsCount: response.data.total_counts
                        })
                    }
                })
        }
    }

    toggleTabs = (tab) => {
        tab === 'all' ? this.setState({ resultsType: "" }) : tab === 'agreements_app' ? this.setState({ resultsType: "agreements" }) : this.setState({ resultsType: tab })
        const { activeTab } = this.state
        if (activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    handlePageClick = (data) => {
        let offset = Math.ceil(data.selected)
        this.setState({ resultsOffset: offset + 1 })
        if (typeof window !== `undefined`) {
            window.scrollTo(0, 0)
        }
    }

    render() {
        const isArabicLocale = this.props.intl.locale === "ar";
        const noSearchResults = <h3><FormattedMessage id="no_search_results" /></h3>

        const tabs = ['all', 'achievements', 'links', 'agreements_app', 'static_maps']
        const { activeTab } = this.state
        var baseURL = process.env.SERVER_URL
        baseURL = baseURL.substring(0, baseURL.length - 1);

        const allResults = this.state.allResults !== null ?
            this.state.allResults.map((result, i) => {
                let goto = ''
                switch (result.index_type) {
                    case 'achievements':
                        goto = "/achievementsDetails/?country=" + this.props.selectedRegionCode + "&id=" + result.id
                        break
                    case 'links':
                        goto = result.url
                        break
                    case 'agreements':
                        goto = "/wildLifeAgreement/?country=" + this.props.selectedRegionCode
                        break
                    default:
                        break
                }
                return (
                    <Col lg={4} md={6} sm={6} key={i}>
                        {result.index_type === 'static_maps' ? (
                            <Card className="no-transition">
                                <CardImg top src={baseURL + result.image || NoImg} alt={isArabicLocale ? result.title_ar : result.title_en} style={{ width: "350px", height: "200px" }} />
                                <CardBody>
                                    <CardTitle>
                                        <h5>
                                            {result.highlight && result.highlight[isArabicLocale ? 'title_ar' : 'title_en'] && result.highlight[isArabicLocale ? 'title_ar' : 'title_en'].map(title => <p dangerouslySetInnerHTML={{ __html: title }} align={isArabicLocale ? "right" : "left"} ></p>)}
                                        </h5>
                                    </CardTitle>
                                </CardBody>
                                <a href={result.doc} target="_blank" rel="noopener noreferrer" className="btn btn-primary">
                                    <FormattedMessage id="download_doc" />
                                </a>
                            </Card>
                        ) : (
                                <Link to={goto}>
                                    <Card className="achievements-card">
                                        <img className="photo" src={result.image ? baseURL + result.image : NoImg} />
                                        <CardBody>
                                            <h5>{isArabicLocale ? result.title_ar : result.title_en}</h5>
                                            <hr align="center" className="hr-achievement-card" />
                                            <CardText style={{ fontSize: "12px" }} >
                                                {result.highlight && result.highlight[isArabicLocale ? 'short_description_ar' : 'short_description_en'] && result.highlight[isArabicLocale ? 'short_description_ar' : 'short_description_en'].map(description => <p dangerouslySetInnerHTML={{ __html: description }} align={isArabicLocale ? "right" : "left"} ></p>)}

                                                {result.highlight && result.highlight[isArabicLocale ? 'description_ar' : 'description_en'] && result.highlight[isArabicLocale ? 'description_ar' : 'description_en'].map(description => <p dangerouslySetInnerHTML={{ __html: description }} align={isArabicLocale ? "right" : "left"} ></p>)}
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Link>
                            )}
                    </Col>
                )
            }) : ""

        const achievementsResults = this.state.achievementsResults !== null ?
            this.state.achievementsResults.map((achievement, i) =>
                <Col lg={4} md={6} sm={6} key={i}>
                    <Link to={"/achievementsDetails/?country=" + this.props.selectedRegionCode + "&id=" + achievement.id}>
                        <Card className="achievements-card">
                            <img className="photo" src={achievement.image ? baseURL + achievement.image : NoImg}/>
                            <CardBody>
                                <h5>{isArabicLocale ? achievement.title_ar : achievement.title_en}</h5>
                                <hr align="center" className="hr-achievement-card" />
                                <CardText style={{ fontSize: "12px" }} >
                                    {achievement.highlight && achievement.highlight[isArabicLocale ? 'short_description_ar' : 'short_description_en'] && achievement.highlight[isArabicLocale ? 'short_description_ar' : 'short_description_en'].map(description => <p dangerouslySetInnerHTML={{ __html: description }} align={isArabicLocale ? "right" : "left"} ></p>)}

                                    {achievement.highlight && achievement.highlight[isArabicLocale ? 'description_ar' : 'description_en'] && achievement.highlight[isArabicLocale ? 'description_ar' : 'description_en'].map(description => <p dangerouslySetInnerHTML={{ __html: description }} align={isArabicLocale ? "right" : "left"} ></p>)}
                                </CardText>
                            </CardBody>
                        </Card>
                    </Link>
                </Col>) : ""

        const linksResults = this.state.linksResults !== null ?
            this.state.linksResults.map((link, i) =>
                <Col lg={4} md={6} sm={6} key={i}>
                    <a href={link.url} target="_blank" rel="noopener noreferrer">
                        <Card>
                            <img className="photo" src={baseURL + link.image || NoImg}/>
                            <CardBody>
                                <h5>{isArabicLocale ? link.title_ar : link.title_en}</h5>
                            </CardBody>
                        </Card>
                    </a>
                </Col>) : ""

        const agreementsResults = this.state.agreementsResults !== null ?
            this.state.agreementsResults.map((agreement, i) =>
                <Row key={i}>
                    <Link to={"/wildLifeAgreement/?country=" + this.props.selectedRegionCode}>
                        <Card style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Col md={3}>
                                <img src={agreement.image !== null ? baseURL + agreement.image : NoImg} alt='image' style={{ borderRadius: '0px' }} />
                            </Col>
                            <Col md={9}>
                                <CardBody>
                                    <h5 align={isArabicLocale ? "right" : "left"}>{isArabicLocale ? agreement.name_ar : agreement.name_en}</h5>
                                    <CardText style={{ fontSize: "12px" }} align={isArabicLocale ? "right" : "left"}>
                                        {agreement.highlight && agreement.highlight[isArabicLocale ? 'text_ar' : 'text_en'] && agreement.highlight[isArabicLocale ? 'text_ar' : 'text_en'].map(text => <p dangerouslySetInnerHTML={{ __html: text }} align={isArabicLocale ? "right" : "left"} ></p>)}
                                    </CardText>
                                </CardBody>
                            </Col>
                        </Card>
                    </Link>
                </Row>
            ) : ""

        const mapsResults = this.state.mapsResults !== null ?
            this.state.mapsResults.map((map, i) =>
                <Col lg={4} md={6} sm={6} key={i}>
                    <Card className="no-transition" >
                        <CardImg top src={map.image !== null ? baseURL + map.image : NoImg} alt={isArabicLocale ? map.title_ar : map.title_en} style={{ width: "350px", height: "200px" }} />
                        <CardBody>
                            {map.highlight && map.highlight[isArabicLocale ? 'title_ar' : 'title_en'] && map.highlight[isArabicLocale ? 'title_ar' : 'title_en'].map(title => <h5 dangerouslySetInnerHTML={{ __html: title }} align={'center'} ></h5>)}
                        </CardBody>
                        <a href={baseURL + map.doc} target="_blank" rel="noopener noreferrer" className="btn btn-primary">
                            <FormattedMessage id="download_doc" />
                        </a>
                    </Card>
                </Col>
            ) : ""

        let pagesCount = this.state.totalResultsCount / 9;
        let pagination = pagesCount > 1 ?
            <ReactPaginate
                previousLabel={<FormattedMessage id="previous" />}
                breakLabel={'...'}
                nextLabel={<FormattedMessage id="next" />}
                pageCount={pagesCount}
                marginPagesDisplayed={0}
                pageRangeDisplayed={2}
                onPageChange={this.handlePageClick}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                className='disabledClassName'
            /> : null

        return (
            <Layout>
                <SEO title="Search" />
                <div className="main">
                    <div className="section text-center">
                        <div className="mt-5 p-4 flex" style={{ backgroundColor: "#d3d3d36e" }} >
                            <Container >
                                <Row>
                                    <h3 className="mt-1"><FormattedMessage id="search_results" /></h3>
                                </Row>
                            </Container>
                        </div>
                        <br />
                        <Container>
                            <Nav tabs className={"justify-content-center maps-nav"}>
                                {tabs.map(tab =>
                                    <NavItem key={tab} className={"navItem" + (activeTab === tab ? " custom_nav " : "")}>
                                        <NavLink
                                            className={"navColor" + (activeTab === tab ? " active " : "")}
                                            onClick={() => { this.toggleTabs(tab) }}>
                                            <h5><FormattedMessage id={tab} /></h5>
                                        </NavLink>
                                    </NavItem>)}
                            </Nav>

                            <TabContent activeTab={activeTab} >
                                <TabPane tabId='all'>
                                    <Row className=" justify-content-center">
                                        {this.state.isLoading ? <Spinner />
                                            : this.state.allResults.length === 0 ? noSearchResults
                                                : allResults}
                                    </Row>
                                    <Row className=" justify-content-center">
                                        {this.state.allResults.length === 1 ? "" : pagination}
                                    </Row>
                                </TabPane>
                                <TabPane tabId='achievements'>
                                    <Row className=" justify-content-center">
                                        {this.state.isLoading ? <Spinner />
                                            : this.state.achievementsResults.length === 0 ? noSearchResults
                                                : achievementsResults}
                                    </Row>
                                    <Row className=" justify-content-center">
                                        {this.state.achievementsResults.length === 1 ? "" : pagination}
                                    </Row>
                                </TabPane>
                                <TabPane tabId='links'>
                                    <Row className=" justify-content-center">
                                        {this.state.isLoading ? <Spinner />
                                            : this.state.linksResults.length === 0 ? noSearchResults
                                                : linksResults}
                                    </Row>
                                    <Row className=" justify-content-center">
                                        {this.state.linksResults.length === 1 ? "" : pagination}
                                    </Row>
                                </TabPane>
                                <TabPane tabId='agreements_app'>
                                    <Row className=" justify-content-center">
                                        {this.state.isLoading ? <Spinner />
                                            : this.state.agreementsResults.length === 0 ? noSearchResults
                                                : agreementsResults}
                                    </Row>
                                    <Row className=" justify-content-center">
                                        {this.state.agreementsResults.length === 1 ? "" : pagination}
                                    </Row>
                                </TabPane>
                                <TabPane tabId='static_maps'>
                                    <Row className=" justify-content-center">
                                        {this.state.isLoading ? <Spinner />
                                            : this.state.mapsResults.length === 0 ? noSearchResults
                                                : mapsResults}
                                    </Row>
                                    <Row className=" justify-content-center">
                                        {this.state.mapsResults.length === 1 ? "" : pagination}
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </Container>
                    </div>
                </div>
            </Layout>
        )
    }
}

const mapStateToProps = state => {
    return {
        regions: state.regions.regions,
        selectedRegion: state.regions.selectedRegion,
    }
}

export default connect(mapStateToProps)(injectIntl(SearchPage))